@import '../assets/_colors.scss'; 
@import '../assets/_sizes.scss'; 

.appButton {
	border: 1px solid $color-border;
	border-radius: 15px;
	padding: 0px 10px 2px;
	width: fit-content;
	white-space: nowrap;
	background-color:  $color-bg;
}

.appButton button {
	border: 0;
}

.darkMode .appButton {
	color:  $color-link-dark;
	background-color:  $color-bg-dark;
}

.appButton:hover {
	background-color: $color-link-bg-hover;
}

.darkMode .appButton:hover {
	background-color: $color-link-bg-hover-dark;
}

.appButton button:disabled,
.appButton button[disabled] {
	opacity: 0.5;
}

.appButton button:disabled:hover {
    cursor:not-allowed
 }

.appButton .appButtonIcon {
	padding-right: 8px;
	height:  15px;
	width:  15px;
}

.appButton.appButton-text,
.appButton.appButton-text:hover,
.appButton.appButton-text button,
.appButton.appButton-text button:hover,
.darkMode .appButton.appButton-text,
.darkMode .appButton.appButton-text:hover
 {
	border: 0;
	background-color: inherit;
	padding: 0;
}

.appButton.appButton-small {
	font-size: $small-text;
}