@import '../assets/_colors.scss'; 
@import '../assets/_sizes.scss'; 

.channelItem {
	width: 100%;
}

.channelItem .appColumn {
	flex-grow: 0;
}

.channelItem .mainData {
	flex-grow: 2;
}

.channelItem .channelTitle {
	font-size: $big-text;
}

.channelItem .channelDescription {
	font-size: $small-text;
}

.channelItem .gotoButton {
	margin-left:  20px;
}

.channelItem .channelDetails {
	margin-top: 10px;
	font-size: $medium-text;
	line-height: $medium-text-line;
}