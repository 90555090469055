
.mainMenu {
	display: flex;
	font-size: calc(10px + 1vmin);
}

.mainMenu ul {
	display: flex;
	flex-direction: row;
	list-style-type: none;
	margin:  0;
}

.mainMenu li {
	padding: 0 10px;
}