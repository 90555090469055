@import '../assets/_colors.scss'; 
@import '../assets/_sizes.scss'; 

.videoItem .duration {
	background-color: #000;
	color: #FFF;
	padding: 2px 4px;
	width: fit-content;
	font-size: $tiny-text;
	flex-grow: 0 !important;
}
.videoItem .videoImg {
	margin-right: 10px;
}

.videoItem .videoImg img {
	width:  120px;
	height:  90px;
}

.videoItem .videoTitle {
	font-weight: bold;
}

.videoItem .videoDescription {
	font-size:  $tiny-text;
}

.videoItem .videoStatistics {
	font-size:  $small-text;
}

.videoItem .videoStatistics span {
	font-weight: bold;
}
