@import '../assets/_colors.scss'; 
@import '../assets/_sizes.scss'; 

.App {
	color: $color-text;
	background-color: $color-bg;
}

.appPage {
	width: 100%;
	padding: 20px;
}

.darkMode .mainHeader,
.darkMode .mainBody {
	color: $color-text-dark;
	background-color: $color-bg-dark;
}

a, .appLink, .appIcon,
a:not([href]):not([class])
{
	color: $color-link;
	text-decoration: none;
	cursor: pointer;
}

.appLink:disabled,
.appLink[disabled],
.appLinkButton:disabled,
.appLinkButton[disabled] {
	opacity: 0.5;
}

.appLink:disabled:hover,
 .appLinkButton:disabled:hover {
    cursor:not-allowed
 }


.appIcon span {
	margin-left: 5px;
}

.appLink {
	display: flex;
	align-items: center;	
}

.appLink img,
.appLink svg {
	margin-right: 5px;
}

.appLinkButton {
	border: 1px solid $color-border;
	border-radius: 15px;
	padding: 0px 10px 2px;
	width: fit-content;
	white-space: nowrap;
}

button {
	border: 1px solid $color-border;
	border-radius: 3px;
	color: $color-link;
	background-color:  $color-bg;
	padding: 0 5px 2px;
}

.darkMode button {
	background-color:  $color-bg-dark;
	color: $color-link-dark;
}

.appLinkButton:hover, 
button:hover {
	background-color: $color-link-bg-hover;
}

.darkMode .appLinkButton:hover,
.darkMode button:hover {
	background-color: $color-link-bg-hover-dark;
}

.appLinkButton-small {
	font-size: $small-text;
}

a:hover, a:not([href]):not([class]):hover, .appLink:hover, .appIcon:hover, .appLinkButton:hover, button:hover {
	color: $color-link-hover;
}

.darkMode a, 
.darkMode a:not([href]):not([class]), 
.darkMode .appLink, 
.darkMode .appIcon, 
.darkMode .appLinkButton, 
.darkMode button {
	color:  $color-link-dark;
}

.darkMode a:hover, 
.darkMode a:not([href]):not([class]):hover, 
.darkMode .appLink:hover, 
.darkMode .appIcon:hover,  
.darkMode .appLinkButton:hover,  
.darkMode button:hover {
	color: $color-link-hover-dark;
}

select {
	cursor: pointer;
	height: 1.7em;
	border-radius: 3px;
	border: 1px solid $color-border;
	margin-right: 5px;
}

.darkMode select {
	background-color: $color-bg-dark;
	color: $color-text-dark;
	border: 1px solid $color-border-dark;
}

.mainBody {
	width:  100%;
	min-height: $panel-height;
	display: flex;
	flex-direction: row;
	align-items: stretch;
}

.mainPanel {
	display: flex;
	align-items: flex-start;
	min-height: $panel-height;
	flex-grow: 2;
	flex-shrink: 2;
	flex-grow: 1;
}

.space {width: 100%; height: 10px;}
.space-right {margin-right: 5px;}
.debug {color: gray;}
.panel {width: 600px; margin: 20px}
.appRow {display: flex; flex-direction: row;}
.appRow-spaced {display: flex; flex-direction: row; justify-content:  space-between;}
.full-row {align-items: stretch;}
.full-row div:last-child {flex-grow: 2;}
.text-right {text-align: right; flex-grow: 2; align-items: flex-end;}
.appColumn {display: flex; flex-direction: column;}
.spaced {justify-content: space-between;}
.baseline {align-items: flex-end;}
.list {width: 100%; display: flex; text-align: left;}
.listItem {width: 100%; border-top: 1px solid $color-border; padding: 10px 0}
.listItem:last-child {border-bottom: 1px solid $color-border;}
.listItem a {text-decoration: none;}
.darkMode .listItem {border-top: 1px solid $color-border-dark}
.darkMode .listItem:last-child {border-bottom: 1px solid $color-border-dark;}
.title {font-size: $title-text; font-weight: bold};
.subtitle {font-size: $subtitle-text; font-weight: bold};
.bigText {font-size: $big-text; line-height: $big-text-line; font-weight: bold};
.mediumText {font-size: $medium-text; line-height: $medium-text-line;};

.intro {
	padding: 10px 0;
}

.functionsBar {
	background-color: $color-bg2;
	padding:  10px;
}

.functionsBar form {
	text-align: right;
}

.darkMode .functionsBar {
	background-color: $color-bg2-dark;
}

.small-text {
	font-size: $small-text;
}

.tiny-text {
	font-size: $tiny-text;
}

.floatItemRight {
	position:  absolute;
	right:  0;
	margin-right: 10px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
