.TTicon {
	width: 24px;
	height: 24px;
	background-size: contain;
	background: url('../assets/icons/blank.png') center center no-repeat;
	border: none;
}


.TTicon-home {background: url('../assets/icons/home.png') center center no-repeat;}
.TTicon-channels {background:  url('../assets/icons/channels.png') center center no-repeat;}
.TTicon-add-channel {background:  url('../assets/icons/add-channel.png') center center no-repeat;}
.TTicon-settings {background:  url('../assets/icons/settings.png') center center no-repeat;}

.darkMode .TTicon-home {background: url('../assets/icons/home-dark.png') center center no-repeat;}
.darkMode .TTicon-channels {background:  url('../assets/icons/channels-dark.png') center center no-repeat;}
.darkMode .TTicon-add-channel {background:  url('../assets/icons/add-channel-dark.png') center center no-repeat;}
.darkMode .TTicon-settings {background:  url('../assets/icons/settings-dark.png') center center no-repeat;}

.darkMode .appLink:hover .TTicon-home {background: url('../assets/icons/home-white.png') center center no-repeat;}
.darkMode .appLink:hover .TTicon-channels {background:  url('../assets/icons/channels-white.png') center center no-repeat;}
.darkMode .appLink:hover .TTicon-add-channel {background:  url('../assets/icons/add-channel-white.png') center center no-repeat;}
.darkMode .appLink:hover .TTicon-settings {background:  url('../assets/icons/settings-white.png') center center no-repeat;}

.TTicon-home, .TTicon-channels,
.darkMode .TTicon-home, .darkMode .TTicon-channels,
.darkMode .appLink:hover .TTicon-home, .darkMode .appLink:hover .TTicon-channels,
{
	background-size: 18px 18px;
}

.TTicon-add-channel,
.darkMode .TTicon-add-channel,
.darkMode .appLink:hover .TTicon-add-channel
{
	background-size: 20px 20px;
}

