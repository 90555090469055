/*
@import '../assets/_colors.scss'; 

@use 'assets/_colors.scss'; // assuming a styles directory under src/
//@use '~nprogress/nprogress'; // loading a css file from the nprogress node module
*/

/* cancel bootstrap things */

	
*, ::after, ::before {
  box-sizing: inherit;
}

