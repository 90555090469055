@import '../assets/_colors.scss';

.mainHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  padding: 20px 10px;
  height: 32px;
  border-bottom: 1px solid $color-border;
}

.darkMode .mainHeader {
  border-bottom: 1px solid $color-border-dark;
}

.App-logo {
	height:  40px;
}