/*
$base-color: #c6538c;
$border-dark: rgba($base-color, 0.88);

.alert {
  border: 1px solid $border-dark;
}

*/

$color-text: #555555;
$color-text2: #666666;
$color-text-red: #f44336;
$color-bg: #FFFFFF;
$color-bg2: #EEEEEE;
$color-bg3: #DDDDDD;
$color-link: #222;
$color-link-hover: #000;
$color-link-bg-hover: #c1cadd;
$color-border:  #c1cadd;

$color-text-dark: #CCCCCC;
$color-text2-dark: #DDD;
$color-text-red-dark: #E36049;
$color-bg-dark: #282c34;
$color-bg2-dark: #31353f;
$color-bg3-dark: #42464f;
$color-link-dark: #ffc039;
$color-link-hover-dark: #FFFFFF;
$color-link-bg-hover-dark: #585e6a;
$color-border-dark: #585e6a;
