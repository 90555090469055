.runningPanel {
	width: 100%;
	justify-content: center;
	align-items:  center;
	display: flex;
}

.runningPanel-full {
	height: 100%;
}

.runningPanel .runningIcon {
	margin: 20px;
	height: 40px;
	width: 40px;
}

