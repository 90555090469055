.advancedSearchVideosForm {
	width: 100%
}

.advancedSearchVideosForm .fieldRow {
	padding-bottom:  10px;
}

.advancedSearchVideosForm input {
	margin-right: 5px;
}