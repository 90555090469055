@import '../assets/_colors.scss';

.appModal .modal-header {
	background-color: $color-bg2;
	color:  $color-text2;
	border-bottom:  0;
}

.appModal .modal-content {
	background-color: $color-bg3;
}

.appModal .modal-footer {
	border: 0;
}
.darkMode .modal-header {
	background-color: $color-bg2-dark;
	color:  $color-text2-dark;
}

.darkMode .modal-content {
	background-color: $color-bg2-dark;
	color:  $color-text-dark;
}

.darkMode .btn-close {
	background-color:  $color-link-dark;
}

.darkMode .btn-close:hover {
	background-color:  $color-link-hover-dark;
}