
$title-text: calc(10px + 3vmin);
$subtitle-text: calc(10px + 2vmin);
$big-text: calc(10px + 1.5vmin);
$big-text-line: calc(10px + 1.8vmin);
$medium-text: calc(10px + 0.70vmin);
$medium-text-line: calc(10px + 1.2vmin);
$small-text: calc(10px + 0.50vmin);
$tiny-text: calc(10px + 0.25vmin);

$footer-height: 30px;
$panel-height: calc(100vh - 126px)