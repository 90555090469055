@import '../assets/_colors.scss'; 
@import '../assets/_sizes.scss'; 

.footer {
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content:  space-between;
	height: $footer-height;
	padding:  10px;
	text-align: center;
	font-size:  $small-text;
	color: $color-text2;
	background-color: $color-bg2;
	border-top: 1px solid $color-border;
}

.darkMode .footer {
	color: $color-text2-dark;
	background-color: $color-bg2-dark;
	border-top: 1px solid $color-border-dark;
}