@import '../assets/_colors.scss'; 

.channelsPage .list {
	flex-direction: row;
	flex-wrap: wrap;
}

.channelsPage .listItem {
	padding: 15px 20px;
	border: 1px solid $color-border;
	width: 300px;
	flex-grow: 1;
}

.darkMode .channelsPage .listItem {
	border: 1px solid $color-border-dark;
}

.channelsPage .channelTitle {width: 100%; min-height: 100px; align-items: center;}
.channelsPage .channelTitle .row {width: 100%;}
.channelsPage .channelTitle .column:first-child {margin-right: 20px;}

.channelsPage .listItem {color: $color-text !important; opacity: 0.75}
.channelsPage .listItem:hover {opacity: 1}
.channelsPage .listItem:hover .channelTitle {color: $color-link !important; }
.darkMode .channelsPage .listItem {color: $color-text-dark !important}
.darkMode .channelsPage .listItem:hover .channelTitle {color: $color-link-dark !important; }

.channelsPage .channelStatistics {
	margin:  10px 0;
}