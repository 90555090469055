@import '../assets/_colors.scss'; 

.contextMenu {
	display: flex;
	flex-direction: column;
	flex-shrink: 0;
	align-items: flex-start;
	font-size: calc(10px + 0.75vmin);
	border-right: 1px solid $color-border;
	width: 170px;
}

.darkMode .contextMenu {
	border-right: 1px solid $color-border-dark;
}

.contextMenu a {
	width:  100%;
}
.contextMenu .item {
	display: flex;
	flex-direction: row;
	padding: 7px 10px;
}
.contextMenu .item:hover {
	cursor: pointer;
	background-color: $color-link-bg-hover;
}

.darkMode .contextMenu .item:hover {
	background-color: $color-link-bg-hover-dark;
}

.contextMenu .TTicon {
	margin-right: 7px;
}
