@import '../assets/_colors.scss'; 
@import '../assets/_sizes.scss'; 

.channelDetails {
	width: 100%;
}

.channelDetails .channelHeader {
	padding-bottom:  20px;
}

.channelDetails .channelTitle {
	font-size: $title-text;
}

.channelDetails .channelDescription {
	font-size: $tiny-text;
}

.channelDetails .channelImg {
	margin-right: 10px;
	border-radius: 50%;
	border: 1px solid $color-border;
	width: 88px;
	height: 88px;
}

.channelDetails .channelImg img {
	width: 88px;
	height: 88px;
}

.channelDetails .channelData {
	font-size: $small-text;
}

.channelDetails .channelData span {
	font-weight: bold;
}
