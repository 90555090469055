.settingsPage {
}


.settingsPage .appRow { 
	width: 100%;
}

.settingsPage .appColumn {
	padding:  10px;
	flex-direction: row;
}

.settingsPage span {
	font-weight: bold;
	padding-left: 20px;
}